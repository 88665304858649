// import axios from 'axios';
// // config
// import { HOST_API_KEY } from '../config-global';

// // ----------------------------------------------------------------------

// const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

// export default axiosInstance;

import axios from 'axios';

const localStorageToken = window.localStorage?.getItem(".")

export const axiosInstance = axios.create({
  // Staging
     baseURL: 'https://backend-staging-sandh.herokuapp.com/api/v1',
  // Production
   // baseURL: 'https://backend-production-sandh.herokuapp.com/api/v1',
    // baseURL: 'http://localhost:8080/api/v1',
    headers: {
     Authorization: `Bearer ${localStorageToken && localStorageToken}`  
    }
  });
  
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
    //   if (error.response.status === 401) {
    //     window.location.href = '/';
    //   }
    });
  
  export default axiosInstance;
  
